/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import cuid from 'cuid';
import usePebblePost from '@cleverrealestate/clever-components-v2/dist/Utils/usePebblePost';
import breadcrumbData from './StructuredData/breadcrumbs';

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        url
      }
    }
    logoImage: file(relativePath: { eq: "logo/CleverOrgLogo.png" }) {
      childImageSharp {
        fixed(width: 628) {
          src
        }
      }
    }
    ogImage: file(relativePath: { eq: "logo/FallbackandMeta.png" }) {
      childImageSharp {
        fixed(width: 1360) {
          src
        }
      }
    }
    cosmicjsWebsiteMetadata {
      metadata {
        organization_schema {
          value
        }
      }
    }
  }
`;

function SEO({
  description, lang, meta, keywords, title, pathname, type, locale,
  image, imageAlt, imageHeight, imageWidth, links, canonical,
  breadcrumbs, publishDateMeta, structuredData, noIndex, addWebsiteName,
}) {
  usePebblePost();
  const getCanonicalLink = (baseUrl) => {
    let urlPath = canonical || pathname;
    if (urlPath.charAt(urlPath.length - 1) !== '/') {
      urlPath = `${urlPath}/`;
    }
    if (urlPath.charAt(0) !== '/') {
      urlPath = `/${urlPath}`;
    }
    return `${baseUrl}${urlPath}`;
  };
  ////
  // TODO: before merge, make sure that the fallback image sizes only
  // get set if the fallback image is used. Otherwise don't set the image size props.
  // Also double check on the OG inspector that this won't break our cards.
  ////
  const getURL = (baseUrl) => {
    let urlPath = pathname;
    if (urlPath.charAt(urlPath.length - 1) !== '/') {
      urlPath = `${urlPath}/`;
    }
    return `${baseUrl}${urlPath}`;
  };

  const fallbackOGImage = imageURL => `https://${process.env.HOSTNAME}${imageURL}`;

  const publishMetaArray = Array.isArray(publishDateMeta) ? publishDateMeta : [];

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription = description || data.site.siteMetadata.description;
        const metaTitle = addWebsiteName ? `${title} | ${data.site.siteMetadata.title}` : title;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
          >
            {/* Title */}
            <title>{metaTitle}</title>
            {/* Links */}
            <link rel="canonical" href={getCanonicalLink(data.site.siteMetadata.url)} />
            <link rel="dns-prefetch" href="https://api.ipify.org" />
            <link rel="dns-prefetch" href="https://js.callrail.com" />
            <link rel="dns-prefetch" href="https://www.googleadservices.com" />
            <link rel="dns-prefetch" href="https://connect.facebook.net" />
            <link rel="dns-prefetch" href="https://googleads.g.doubleclick.net" />
            <link rel="dns-prefetch" href="https://www.google.com" />
            <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
            {links.map(link => (
              <link key={cuid.slug()} rel={link.rel || 'dns-prefetch'} href={link.href} />
            ))}
            {/* Meta */}
            {noIndex && <meta name="robots" content="noindex" />}
            <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,minimum-scale=1" />
            <meta name="description" content={metaDescription} />
            <meta property="og:title" content={metaTitle} />
            <meta property="clever:title" content={`${title}`} />
            <meta property="og:type" content={type} />
            <meta property="og:locale" content={locale} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:url" content={getURL(data.site.siteMetadata.url)} />
            <meta name="site_name" property="og:site_name" content="Clever Real Estate" />
            <meta property="og:image" content={image || fallbackOGImage(data.ogImage.childImageSharp.fixed.src)} />
            <meta property="og:image:secure_url" content={image || fallbackOGImage(data.ogImage.childImageSharp.fixed.src)} />
            {image && imageWidth && (<meta property="og:image:width" content={imageWidth} />)}
            {image && !imageWidth && (<meta property="og:image:width" content="1200" />)}
            {image && imageHeight && (<meta property="og:image:height" content={imageHeight} />)}
            {!image && (<meta property="og:image:width" content="1360" />)}
            {!image && (<meta property="og:image:height" content="680" />)}
            <meta property="og:image:alt" content={image ? (imageAlt || title) : 'Clever Real Estate'} />
            <meta name="article:publisher" content="https://www.facebook.com/listwithclever/" />
            <meta name="twitter:image" content={image || fallbackOGImage(data.ogImage.childImageSharp.fixed.src)} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={data.site.siteMetadata.author} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={metaDescription} />
            {keywords.length > 0 && <meta name="keywords" content={keywords.join(', ')} />}
            {publishMetaArray.map(publishMeta => <meta key={cuid.slug()} property={publishMeta.name} content={publishMeta.content} />)}
            {meta.map(metaValues => <meta key={cuid.slug()} name={metaValues.name} content={metaValues.content} />)}
            {/* Scripts */}
            <script type="text/javascript" src="//js.hs-scripts.com/3298701.js" async />
            {/* Include unbounce only on top agent pages */}
            {
              pathname && pathname.match(/^\/?top-real-estate-agents\/?/) && (
                <script src="https://96c0db0f6b5445868eb2f99e88231179.js.ubembed.com" async />
              )
            }
            {
              pathname === '/' && (
                <script type="application/ld+json">
                  {data.cosmicjsWebsiteMetadata.metadata.organization_schema.value}
                </script>
              )
            }
            {structuredData && Array.isArray(structuredData) && structuredData.length > 0 && structuredData[0].json !== '' && structuredData.map(sd => (
              <script type="application/ld+json" key={cuid.slug()}>
                {JSON.stringify(sd.json)}
              </script>
            ))}
            {
              breadcrumbs && Array.isArray(breadcrumbs) && breadcrumbs.length > 0 && breadcrumbData(breadcrumbs, pathname) && (
                <script type="application/ld+json">
                  {JSON.stringify(breadcrumbData(breadcrumbs, pathname))}
                </script>
              )
            }
            <link rel="preload" href="/fonts/Inter-Medium-subset.woff2" as="font" type="font/woff2" key="/fonts/Inter-Medium-subset.woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/Inter-Regular-subset.woff2" as="font" type="font/woff2" key="/fonts/Inter-Regular-subset.woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/subset-Inter-Regular-Italic.woff2" as="font" type="font/woff2" key="/fonts/subset-Inter-Regular-Italic.woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/subset-Rubik-Medium.woff2" as="font" type="font/woff2" key="/fonts/subset-Rubik-Medium.woff2" crossOrigin="anonymous" />
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  description: '',
  lang: 'en',
  locale: 'en_US',
  meta: [],
  keywords: [],
  links: [],
  type: 'website',
  image: null,
  imageHeight: null,
  imageWidth: null,
  imageAlt: null,
  addWebsiteName: true,
  canonical: null,
  breadcrumbs: null,
  publishDateMeta: null,
  structuredData: null,
  noIndex: false,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  locale: PropTypes.string,
  meta: PropTypes.array,
  type: PropTypes.string,
  image: PropTypes.string,
  imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageAlt: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.object),
  addWebsiteName: PropTypes.bool,
  canonical: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  publishDateMeta: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.string,
    }),
  ),
  structuredData: PropTypes.arrayOf(
    PropTypes.shape({
      json: PropTypes.object,
    }),
  ),
  noIndex: PropTypes.bool,
};

export default SEO;
