const breadcrumbData = (breadcrumbs) => {
  const data = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [],
  };
  for (let i = 0; breadcrumbs.length > i; i += 1) {
    const crumb = breadcrumbs[i];
    // Validate the data, we don't want a bad array to mess with SEO
    const isValid = typeof crumb.title === 'string'
      && typeof crumb.link === 'string'
      && crumb.title.length > 0
      && crumb.link.length > 0;
    if (!isValid) {
      return null;
    }

    let urlPath = crumb.link;
    if (urlPath.charAt(urlPath.length - 1) !== '/') {
      urlPath = `${urlPath}/`;
    }
    if (urlPath.charAt(0) !== '/') {
      urlPath = `/${urlPath}`;
    }
    const crumbData = {
      '@type': 'ListItem',
      position: i + 1,
      name: crumb.title,
      item: `https://${process.env.HOSTNAME}${urlPath}`,
    };
    data.itemListElement.push(crumbData);
  }
  return data;
};

export default breadcrumbData;
